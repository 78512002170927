.busqueda {
    margin-bottom: 20px;
    /* 2rem */
}

.display-busqueda {
    padding-right: 40px;
    /* 4rem */
    width: 673px;
    /* 67.3rem */

    display: flex;
    align-items: center;
    position: relative;
}

.barra-busqueda {
    display: flex;
    align-items: center;
    border: 1px solid #3E3E3F;
    border-radius: 10px;
    /* 1rem */
}

.display-busqueda input {
    border: 1px solid #3E3E3F;
    border-radius: 10px;
    /* 1rem */

    font-size: 20px;
    /* 2.4rem */
    font-weight: 400;

    width: 100%;
    padding: 10px;
    /* 1rem */
}

.display-busqueda.modo-oscuro input {
    background-color: #666;
    color: white;
}

.display-busqueda i {
    position: absolute;
    right: 7%;
    font-size: 20px;
    color: #57585A;
}

.display-busqueda.modo-oscuro i{
    color: white;
}

@media (max-width: 730px) {
    .display-busqueda {
        width: 100%;
        padding-right: 0px;
    }

    .display-busqueda i {
        right: 4%;
    }
}