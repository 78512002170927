.empleado {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px; 

    background-color: white;

    width: 100%;

    text-align: center;

    box-sizing: border-box;

    cursor: pointer;
}

.empleado.modo-oscuro{
    background-color:#424242;
}

.empleado:hover {
    background-color: #e6e6e6;
}

.empleado.empleado.modo-oscuro:hover{
    background-color:#666666;
}

.empleado .nombre-apellido {
    color: #2681C4;
    font-weight: bold;
    font-size: 17px; 
}

.empleado .puesto{
    color: #3f3f3f;
    font-size: 15px;
}

.empleado.modo-oscuro .nombre-apellido{
    font-weight: 900;
}

.empleado.modo-oscuro .puesto{
    color: white;
}

@media(max-width: 880px){
    .empleado{
        flex-direction: column;
    }
}
