.dialogo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fade-in 0.2s ease-in forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dialogo {
  background-color: white;
  padding: 20px; /* 20px */
  border-radius: 8px; /* 8px */
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px; /* 2rem */
}

.dialogo.modo-oscuro {
  background-color: #2e2e2e;
}

.dialogo table {
  user-select: none;
}

.dialogo-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* 1rem */
  width: 100%;
}

.dialogo-buttons .button {
  width: 100%;
  font-size: 20px; /* 2rem */
  cursor: pointer;
  border: none;
  border-radius: 5px; /* 0.5rem */
  font-weight: bold;
}

.dialogo-buttons .button.copiar {
  background-color: #2681c4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* 1rem */
}

.dialogo-buttons .button.copiar i {
  font-size: 15px; /* 1.5rem */
}

.dialogo-buttons .button.copiar:hover {
  background-color: #459edd;
}

.dialogo-buttons .button.cancelar {
  border: 2px #2681c4 solid; /* 0.2rem */
  color: #2681c4;
}
