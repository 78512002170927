.tutorial {
    margin-top: 20px; 
    margin-bottom: 80px; 
    display: flex;
    overflow: hidden;

    transition: height 0.7s ease;
}

.tutorial ul {
    list-style-type: none;
}

.tutorial li {
    margin-bottom: 10px;
    opacity: 0;
}

.tutorial li.show-instruction{
    animation: slide-in 0.5s ease-out forwards;
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-10px); 
    }

    100% {
        opacity: 1;
        transform: translateY(0px); 
    }
}

.tutorial li p.spaced-info {
    margin-top: 5px; 
}

.tutorial i {
    font-size: 20px;  
    margin: 0px 5px;  
    transform: translateX(-10px) rotate(-90deg); 
    animation: roll-in 1s ease-out forwards;
}

@keyframes roll-in {
    0% {
        transform: rotate(-90deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.side-bar {
    width: 2px; 
    background-color: #D1D2D4;
    margin-right: 20px; 
}
