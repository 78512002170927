.main {
    padding: 0px 30px;
}

.main h1 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #D1D2D4;
    margin-bottom: 20px;
    
    padding: 20px 0px;
}

.main p {
    font-size: 17px;
    font-weight: 400;
}

.main #boton-display-tutorial {
    color: #2681C4;
    text-decoration: underline;
    cursor: pointer;
}

.main h2 {
    font-size: 17px;
    font-weight: 600;
}

